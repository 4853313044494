import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Profile from "../../images/profile-picture.jpg";
import { CiSearch } from "react-icons/ci";
import "./style.css";
import { FaBarsStaggered } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import { getSearchEmails, userDatabyid } from "../../Api/ExportApi";
import { RxCross2 } from "react-icons/rx";
import { BiLoaderCircle } from "react-icons/bi";

const Header = ({ setOpen, open }) => {
  const [userData, setUserData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [authtoken, setAuthToken] = useState();
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null);
  const searchRefWeb = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          const resp = await userDatabyid(userId);
          setUserData(resp?.data?.user);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    if (window.innerWidth <= 767) {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRefWeb.current && !searchRefWeb.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    if (window.innerWidth > 767) {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    }
  }, []);

  const handleSearch = async (e) => {
    if (e.key === "Enter" || e.type === "click") {
      setLoading(true);
      console.log(userData);

      try {
        const params = {
          search: searchQuery,
          userId: userData?._id,
        };
        const response = await getSearchEmails(params);

        setSearchResults(response.data.result);
        setShowResults(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error searching emails:", err);
      }
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <div className="justify-between items-center px-3 lg:px-10 py-3 hidden md:flex header-inner w-full">
        <FaBarsStaggered
          onClick={() => setOpen(open === "small" ? "large" : "small")}
          className="text-2xl cursor-pointer"
        />
        <div className="flex items-center gap-2.5 w-1/2 md:w-3/4 justify-end">
          <div className="relative lg:w-[80%] xl:w-[40%]" ref={searchRefWeb}>
            <input
              type="search"
              className="search-bar border rounded-md focus:outline-0 py-2 ps-3 pe-7 shadow-[rgba(0, 0, 0, 0.16) 0px 1px 4px] text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#008bfb] placeholder:text-gray-400"
              placeholder="Search here"
              value={searchQuery}
              autoComplete="off"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearch}
            />
            {searchQuery.length > 0 && (
              <RxCross2
                className="w-[20px] h-[20px] absolute right-12 top-[10px] cursor-pointer z-30"
                onClick={() => setSearchQuery("")}
              />
            )}
            <CiSearch
              className="search absolute cursor-pointer right-0 top-0 ms-2 text-[#fff] bg-[#2a79bd] text-white h-[42px] w-[42px] p-3 rounded-md"
              onClick={handleSearch}
            />
            {loading ? (
              <div className="search-results bg-white shadow-md mt-2 p-4 rounded-lg w-full flex justify-center">
                <BiLoaderCircle className="animate-spin" /> Loading...
              </div>
            ) : (
              showResults && (
                <div className="search-results bg-white shadow-md mt-2 p-4 rounded-lg w-full absolute z-50">
                  {searchResults?.length > 0 ? (
                    <ul className="list-disc pl-5 h-[600px] overflow-y-scroll">
                      {searchResults.map((result, index) => (
                        <Link
                          to={`/singleinbox/${result?._id}`}
                          className="font-medium"
                          // onClick={() => setShowResults(false)}
                        >
                          <li
                            key={index}
                            className="mb-2 text-sm text-gray-700 list-none hover:bg-[#e4f3ff]"
                          >
                            {result?.subject || "No Subject"}
                            <p className="text-xs text-gray-500">
                              {stripHtmlTags(result?.body?.slice(0, 40))}
                            </p>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  ) : (
                    <div>No results found.</div>
                  )}
                </div>
              )
            )}
          </div>
          <Link to="/profile" className="ms-3 flex items-center gap-2">
            {userData?.profilePic ? (
              <img
                className="w-[40px] h-[40px] rounded-full"
                src={`${userData?.profilePic}`}
                alt="profile"
              />
            ) : (
              <img
                className="w-[40px] h-[40px] rounded-full"
                src={Profile}
                alt="profile"
              />
            )}
            {/* <p className="text-base font-medium text-[#2a79bd]">
              {userData?.firstName}
            </p> */}
          </Link>
        </div>
      </div>

      {/* Mobile */}
      <div className="justify-between gap-2.5 items-center px-5 lg:px-10 py-3 bg-white shadow mobile flex md:hidden header-inner">
        <FaBarsStaggered
          onClick={() => setOpen(open === "none" ? "large" : "none")}
          className="text-2xl cursor-pointer"
        />
        <div className="relative w-[1/2] lg:w-3/12" ref={searchRef}>
          <input
            type="search"
            className="search-bar border rounded-md focus:outline-0 py-2 ps-3 pe-7 shadow-[rgba(0, 0, 0, 0.16) 0px 1px 4px] text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#008bfb] placeholder:text-gray-400"
            placeholder="Search here"
            value={searchQuery}
            autoComplete="off"
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearch}
          />
          {searchQuery.length > 0 && (
            <RxCross2
              className="w-[20px] h-[20px] absolute right-12 top-[10px] cursor-pointer z-30"
              onClick={() => setSearchQuery("")}
            />
          )}
          <CiSearch
            className="search absolute right-0 top-0 ms-2 text-[#fff] bg-[#2a79bd] text-white h-[42px] w-[42px] p-3 rounded-md"
            onClick={handleSearch}
          />
          {loading ? (
            <div className="search-results bg-white shadow-md mt-2 p-4 rounded-lg w-full flex justify-center">
              <BiLoaderCircle className="animate-spin" /> Loading...
            </div>
          ) : (
            showResults && (
              <div className="search-results bg-white shadow-md mt-2 p-4 h-[500px] rounded-lg w-full absolute z-50 overflow-y-hidden">
                {searchResults?.length > 0 ? (
                  <ul className="list-disc pl-5 h-[600px] overflow-y-scroll">
                    {searchResults.map((result, index) => (
                      <Link
                        to={`/singleinbox/${result?._id}`}
                        className="font-medium"
                        onClick={() => setShowResults(false)}
                      >
                        <li
                          key={index}
                          className="mb-2 text-sm text-gray-700 list-none hover:bg-[#e4f3ff]"
                        >
                          {result?.subject || "No Subject"}
                          <p className="text-xs text-gray-500">
                            {stripHtmlTags(result?.body?.slice(0, 40))}
                          </p>
                        </li>
                      </Link>
                    ))}
                  </ul>
                ) : (
                  <div>No results found.</div>
                )}
              </div>
            )
          )}
        </div>
        <Link to="/profile" className="flex items-center">
          {userData?.profilePic ? (
            <img
              className="w-[40px] h-[40px] rounded-full"
              src={`${userData?.profilePic}`}
              alt="profile"
            />
          ) : (
            <img
              className="w-[40px] h-[40px] rounded-full"
              src={Profile}
              alt="profile"
            />
          )}
        </Link>
      </div>
    </>
  );
};

export default Header;
