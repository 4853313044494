import React, { useEffect, useState } from "react";
import Login from "../../../../src/images/login-images.jpg";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

// import { APICore } from "../../../api/apiCore";
import ToastHandle from "../../helpers/ToastMessage";
import { isAuthenticated } from "../../helpers/AuthConditionCheck";
import {
  createRoute,
  createSmtpUser,
  domainVerify,
  userRegister,
  verifyUserDomain,
} from "../../../Api/ExportApi";

// const api = new APICore();

const Signup = () => {
  const navigate = useNavigate();
  const authLocation = isAuthenticated();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    domain: "",
    email: "",
    password: "",
    confirmPassword: "",
    alternativeEmail: "",
  });

  const [domainAdd, setDomainAdd] = useState(false);
  const [domainVerified, setDomainVerified] = useState(false);
  const [domainLoading, setDomainLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle special case for email field
    // if (name === "email" && formData.domain) {
    //   // Append domain to the email if not present
    //   const domain = formData.domain;
    //   let email = value;

    //   // if (!email.endsWith(`@${domain}`)) {
    //   //   email = `${email.split('@')[0]}@${domain}`;
    //   //   // Optionally set an error message here if you want
    //   //   // setEmailErrorMessage(`Email must end with @${domain}`);
    //   // } else {
    //   //   // Optionally clear the error message if needed
    //   //   // setEmailErrorMessage("");
    //   // }

    //   setFormData(prevData => ({ ...prevData, [name]: email }));
    // } else {
    // Update form data for other fields
    setFormData((prevData) => ({ ...prevData, [name]: value.trim() }));
    // }
  };
  /// ---------------------------------------

  const [domain, setDomain] = useState(true);

  // const findDomainHnld = async (e) => {
  //   e.preventDefault();
  //   if (formData?.domainName !== "") {
  //     const data = { domain: formData?.domainName };
  //     try {
  //       const response = await api.get(
  //         `${URL.IS_EXIST}${formData?.domainName}`
  //       );
  //       if (response?.status === 200) {
  //         const findDomainStatus = response?.data?.isExist;
  //         const findDomainMessage = response?.data?.message;
  //         if (findDomainStatus) {
  //           ToastHandle(findDomainMessage, "success");
  //           setDomain(false);
  //         }
  //       } else {
  //         const verificationDomainMessage = response?.data?.message;
  //         ToastHandle(verificationDomainMessage, "danger");
  //       }
  //     } catch (error) {
  //       console.error("Login failed:", error);
  //     }
  //   }
  // };

  const validateForm = () => {
    const {
      firstName,
      lastName,
      email,
      alternativeEmail,
      password,
      confirmPassword,
      domain,
    } = formData;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !alternativeEmail ||
      !password ||
      !confirmPassword ||
      !domain
    ) {
      ToastHandle("All fields are required.", "danger");
      return false;
    }

    if (password !== confirmPassword) {
      ToastHandle("Passwords do not match.", "danger");
      return false;
    }

    return true;
  };

  const authRegisterHndle = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (formData?.domain !== "") {
      if (formData.password !== formData.confirmPassword) {
        ToastHandle("Passwords do not match.", "danger");
      } else {
        const {
          firstName,
          lastName,
          domain,
          email,
          password,
          alternativeEmail,
        } = formData;

        const emailWithDomain = email + "@" + domain;
        try {
          const smptUserResponse = await createSmtpUser(
            emailWithDomain,
            domain
          );

          if (
            smptUserResponse?.data?.message === "This email is already in use"
          ) {
            ToastHandle("This email is already in use", "danger");
            return;
          }

          if (smptUserResponse.status === 201) {
            const createRouteResponse = await createRoute(domain);

            if (createRouteResponse.status === 200) {
              const response = await userRegister({
                firstName,
                lastName,
                domain,
                email: emailWithDomain,
                alternativeEmail,
                password,
              });
              if (response.status === 201) {
                const registerUserMessage = response?.data?.message;
                ToastHandle(registerUserMessage, "success");
                navigate("/login");
              } else {
                const registerUserMessage = response?.data?.message;
                ToastHandle(registerUserMessage, "danger");
                navigate("/login");
              }
            }
          }
        } catch (error) {
          console.error("Login failed:", error);
        }
      }
    }
  };

  const handleDomainVerify = async () => {
    setDomainLoading(true);
    const resp = await domainVerify(formData.domain);

    if (resp.data?.id) {
      if (resp.data?.state !== "active") {
        console.log("Domain not verified");
        ToastHandle("Domain not verified", "danger");
        setDomainVerified(false);
        setDomainLoading(false);
        return;
      }

      ToastHandle("Domain verified successfully", "success");
      setDomainVerified(true);
      setDomainLoading(false);
    } else {
      console.log("Domain not found");
      ToastHandle("Domain not found", "danger");
      setDomainVerified(false);
      setDomainLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (authLocation) {
      navigate("/");
    } else {
      navigate("/register");
    }
  }, []);


  const launchEntri = async () => {
    setDomainLoading(true);
    if (typeof window.entri === 'undefined') {
      console.error('Entri SDK not loaded.');
      setDomainLoading(false);
      return;
    }
  
    try {

      const response = await verifyUserDomain(formData.domain);

      if (response.status !== 200) {
        setDomainLoading(false);
        throw new Error('Failed to verify domain');
      }
  
      const data = await response.data;
  
      if (data.authToken && data.appId) {
        const config = {
          applicationId: data.appId,
          token: data.authToken,
          dnsRecords: data.config.dnsRecords,
        };
  
        window.entri.showEntri(config);
        setDomainLoading(false);
      }
    } catch (error) {
      setDomainLoading(false);
      console.error('Error launching Entri:', error);
    }
  };
  

  return (
    <div className="banner flex justify-center items-center flex-wrap py-10 lg:py-0">
      <div className="w-full md:w-1/2 px-0 xl:px-[150px] mx-auto mb-3 xl:mb-0">
        <h1 className="md:px-10 px-5 font-semibold text-4xl text-center mb-6">
          Register
        </h1>
        {/* <p className="md:px-10 px-5 mb-8 text-[#333] text-base leading-6">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text.
        </p> */}
        <div className="md:px-10 px-5">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 disabled:hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            disabled={domainVerified ? true : false}
          >
            {domainAdd ? "Add domain" : "Verifying domain"}
          </button>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 disabled:bg-blue-400 disabled:hover:bg-blue-400 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            disabled={domainVerified ? false : true}
          >
            Personal Information
          </button>
        </div>

        <form className="space-y-6 md:px-10 px-5" onSubmit={authRegisterHndle}>
          {!domainVerified ? (
            <>
              <div className="mt-2 relative">
                <input
                  id="domainName"
                  name="domain"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter domain name"
                  value={formData.domain}
                  onChange={handleChange}
                  className="relative block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                />
              </div>

              {!domainAdd ? (
                <button
                  type="button"
                  className="text-sm text-[#2a79bd] underline !mt-2"
                  onClick={() => setDomainAdd(true)}
                >
                  Add your domain
                </button>
              ) : (
                <button
                  type="button"
                  className="text-sm text-[#2a79bd] underline !mt-2"
                  onClick={() => setDomainAdd(false)}
                >
                  Verify domain
                </button>
              )}
            </>
          ) : (
            <>
              <InputField
                id="firstName"
                name="firstName"
                type="text"
                placeholder="Enter first name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <InputField
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Enter last name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
              <div className="flex items-center">
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter your new email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 rounded-tr-none rounded-br-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                />
                <div className="bg-gray-200 p-2.5 rounded-md rounded-tl-none rounded-bl-none">
                  <p>@{formData.domain}</p>
                </div>
              </div>
              <InputField
                id="alternativeEmail"
                name="alternativeEmail"
                type="email"
                placeholder="Enter your alternative email"
                value={formData.alternativeEmail}
                onChange={handleChange}
                required
              />{" "}
              <span>{formData?.domainName}</span>
              <InputField
                id="password"
                name="password"
                type="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <InputField
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </>
          )}

          <div>
            {!domainVerified ? (
              <>
                {domainAdd ? (
                  <button
                    // onClick={findDomainHnld}
                    className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={formData?.domain === "" || domainLoading}
                    type="button"
                    onClick={launchEntri}
                  >
                    {domainLoading ? "Please Wait..." : "Add domain"}
                  </button>
                ) : (
                  <button
                    // onClick={findDomainHnld}
                    className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={formData?.domain === "" || domainLoading}
                    type="button"
                    onClick={handleDomainVerify}
                  >
                    {domainLoading ? "Please Wait..." : "Verifying domain"}
                  </button>
                )}
              </>
            ) : (
              <button
                type="submit"
                className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign up
              </button>
            )}
          </div>
        </form>

        <p className="text-sm text-black text-center mt-2 mb-0">
          Already have an account?{" "}
          <Link to="/login" className="font-medium text-[#2a79bd] underline">
            Login
          </Link>
        </p>
      </div>
      <div className="w-full md:w-1/2 flex items-center right-section">
        <img className="p-10" src={Login} alt="Login visual" />
      </div>
    </div>
  );
};

const InputField = ({
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  disabled,
}) => (
  <div className="mt-2">
    <input
      id={id}
      name={name}
      type={type}
      autoComplete="off"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required
      className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
    />
  </div>
);

export default Signup;
